.post {
    width: 380px;
    height: 280px;
    margin: 30px 25px 40px 90px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .postImg {
    width: 385px;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
  }
  
  .postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .postTitle {
    font-size: 24px;
    font-weight: 900;
    margin-top: 15px;
    cursor: pointer;
  }
  
  .postDate {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    margin-top: 15px;
  }
  
  .postDesc {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 20px;
  }