.singlePost {
    flex: 5;
    padding-top: 50px;
  }
  
  .singlePostWrapper {
    padding: 30px;
  }
  
  .singlePostImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .singlePostTitle {
    text-align: center;
    margin: 10px;
    font-size: 28px;
    font-family: "Lora", sans-serif;
  }
  
  .singlePostEdit {
    float: right;
    font-size: 16px;
  }
  
  .singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .singlePostIcon:first-child {
    color: teal;
  }
  .singlePostIcon:last-child {
    color: tomato;
  }
  
  .singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #be9656;
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
  }
  
  .singlePostAuthor{
    margin-left: 5px;
  }
  
  .singlePostDesc {
    color: #666;
    font-size: 18px;
    line-height: 25px;
    margin: auto;
    white-space: pre-wrap;
  }
  